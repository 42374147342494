import React, {
  useMemo,
  useRef,
  useCallback,
  useState,
  useEffect,
} from "react";
import ForceGraph3D from "react-force-graph-3d";
import SpriteText from "three-spritetext";
import "./FGraph.css";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const colorsArr = [
  { color: "#00C29B", name: "Application Development" },
  { color: "#8338EB", name: "Infrastructure Development" },
  { color: "#243D42", name: "Technical Operations and End User Support" },
  { color: "#00ECFF", name: "Data Management and Security" },
  { color: "#8BDB24", name: "Enterprise Information Architecture" },
  { color: "#BF0D9F", name: "Business and Systems Analysis" },
  { color: "#E70411", name: "Software Engineering" },
  { color: "#FF8F1A", name: "General Executive" },
  { color: "#FFC759", name: "Cyber Security" },
  { color: "#54A0FF", name: "Blockchain" },
  { color: "#0062CC", name: "General Non Executive" },
];

const getColor = (name) => {
  const color = colorsArr.find((c) => c.name === name);
  return color ? color.color : "#ffffff";
};

const FGraph = ({ graphData, color, nodeColor, functions }) => {
  const fgRef = useRef();
  const [selectedNode, setSelectedNode] = useState(null);
  const [fromNode, setFromNode] = useState([]);
  const [toNode, setToNode] = useState([]);

  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const [hoverNode, setHoverNode] = useState(null);
  const data = useMemo(() => {
    graphData.links.forEach((link) => {
      const a = graphData.nodes.filter((itm) => {
        return itm.id === link.source;
      })[0];
      const b = graphData.nodes.filter((itm) => itm.id === link.target)[0];
      a && !a.neighbors && (a.neighbors = []);
      b && !b.neighbors && (b.neighbors = []);
      const hasANeighbor = a && a.neighbors.find((aN) => a.id === aN.id);
      const hasBNeighbor = b && b.neighbors.find((bN) => b.id === bN.id);
      !hasANeighbor && a && b && a.neighbors.push(b);
      !hasBNeighbor && a && b && b.neighbors.push(a);

      a && !a.links && (a.links = []);
      b && !b.links && (b.links = []);
      const hasALink = a && a.links.find((aN) => a.id === aN.id);
      const hasBLink = b && b.links.find((bN) => b.id === bN.id);
      !hasALink && a && a.links.push(link);
      !hasBLink && b && b.links.push(link);
    });
    return graphData;
  }, [graphData]);

  useEffect(() => {
    if (selectedNode) {
      setFromNode([]);
      setToNode([]);
      selectedNode.links.forEach((link) => {
        if (link.target.id !== selectedNode.id) {
          setFromNode((oldArray) => [...oldArray, link.target.name]);
        } else {
          setToNode((oldArray) => [...oldArray, link.source.name]);
        }
      });
    }
  }, [selectedNode]);

  const updateHighlight = () => {
    setHighlightNodes(highlightNodes);
    setHighlightLinks(highlightLinks);
  };

  const handleNodeHover = (node) => {
    highlightNodes.clear();
    highlightLinks.clear();
    if (node) {
      highlightNodes.add(node);
      node.neighbors.forEach((neighbor) => {
        highlightNodes.add(neighbor);
      });
      node.links.forEach((link) => highlightLinks.add(link));
    }

    setHoverNode(node || null);
    updateHighlight();
    console.log("highlight", node);
  };

  const formatNodeLayout = (node) => {
    if (highlightNodes.has(node) && node !== hoverNode) {
      const sprite = new SpriteText(`${node.title}`);
      sprite.textHeight = 4;
      sprite.color = color === "black" ? "#fff" : "#000";
      sprite.center.set(0.5, 2);
      return sprite;
    } else {
    }
  };

  const handleClick = useCallback(
    (node) => {
      const handleRender = (node) => {
        setTimeout(() => {
          highlightNodes.clear();
          highlightLinks.clear();
          if (node) {
            highlightNodes.add(node);
            node.neighbors.forEach((neighbor) => {
              highlightNodes.add(neighbor);
            });
            node.links.forEach((link) => highlightLinks.add(link));
          }
          setHoverNode(node || null);
          setSelectedNode(node);
        }, 100);
      };

      if (node && node !== data.nodes[0]) {
        const distance = 200;
        const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);
        const nodes = fgRef.current;
        nodes.cameraPosition(
          {
            x: node.x * distRatio,
            y: node.y * distRatio,
            z: node.z * distRatio,
          },
          node,
          3000
        );
        // nodes.refresh();
        handleRender(node);
      }
    },
    [fgRef, data.nodes, highlightLinks, highlightNodes]
  );

  return (
    <div>
      <div className="fGraph">
        <div className="fGraphContainer">
          <ForceGraph3D
            ref={fgRef}
            graphData={data}
            nodeAutoColorBy={nodeColor}
            nodeColor={(d) => {
              return getColor(d.subFunction);
            }}
            linkDirectionalParticleWidth={2}
            linkDirectionalParticles={(link) =>
              highlightLinks.has(link) ? 6 : 0
            }
            onNodeDragEnd={(node) => {
              node.fx = node.x;
              node.fy = node.y;
              node.fz = node.z;
            }}
            nodeThreeObjectExtend={true}
            linkOpacity={0.45}
            linkWidth={(link) => (highlightLinks.has(link) ? 3 : 1)}
            onNodeClick={handleClick}
            onNodeHover={handleNodeHover}
            linkDirectionalParticleColor={(link) => link.source.color}
            backgroundColor={color !== "black" ? "#f9f9f9" : "#000"}
            nodeThreeObject={formatNodeLayout}
          />
        </div>
        {selectedNode && (
          <div className="graphCol">
            <div className="jobDetail">
              <div className="graphCard_kf">
                <div className="graphCardHeader_lite">
                  <div
                    style={{
                      marginTop: "24px",
                      marginLeft: "65px",
                      fontFamily: "Gotham;",
                    }}
                  >
                    <p className="panelTitle">Node Details</p>
                  </div>
                </div>
                <div
                  className="panelItems"
                  style={{
                    marginTop: "24px",
                  }}
                >
                  <p>
                    <b>Title: </b>
                    {selectedNode.title}
                  </p>
                  <p>
                    <b>Sub Function:</b> {selectedNode.subFunction}
                  </p>
                  <p>
                    <b>Level: </b>
                    {selectedNode.level}
                  </p>
                  <p>
                    <b>Sub Level: </b>
                    {selectedNode.subLevel}
                  </p>
                  <p>
                    <b>Grade: </b>
                    {selectedNode.grade}
                  </p>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#00C29B",
                      width: "250px",
                      height: "40px",
                      color: "black",
                    }}
                  >
                    Explore Organization
                  </Button>
                </div>
              </div>
            </div>
            <div className="rDetail">
              <div className="graphCard_kf">
                <div className="graphCardHeader_lite">
                  <div
                    style={{
                      marginLeft: "24px",
                      fontFamily: "Gotham",
                    }}
                  >
                    <Stack spacing={0.25} direction="column">
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        Source Nodes - {[...new Set(fromNode)].length}
                      </p>
                      <div
                        // className="panelItems"
                        style={{
                          fontSize: "14px",
                          lineHeight: "1.5",
                        }}
                      >
                        {[...new Set(fromNode)].map((node, index) => {
                          return (
                            <p key={"fromnode-" + index}>
                              {index + 1}. {node}
                            </p>
                          );
                        })}
                        {fromNode.length === 0 && <p>No Source Nodes</p>}
                      </div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        Target Nodes - {[...new Set(toNode)].length}
                      </p>
                      <div
                        // className="panelItems"
                        style={{
                          fontSize: "14px",
                        }}
                      >
                        {[...new Set(toNode)].map((node, index) => {
                          return (
                            <p key={"tonode-" + index}>
                              {index + 1}. {node}
                            </p>
                          );
                        })}
                        {toNode.length === 0 && <p>No Target Nodes</p>}
                      </div>
                    </Stack>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FGraph;
