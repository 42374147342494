// import sampleData from "./sampleData.json";

// function getGraphFunctions() {
//   return sampleData.graph.metadata.function;
// }

const getGraphFunctions = (sampleData) => {
  return [...new Set(sampleData.graph.nodes.map((node) => node.subFunction))];
};

export { getGraphFunctions };
