import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./components/Header";
// import _3DGraph from "./components/_3DGraph";
// import _2DGraph from "./components/_2DGraph";
import Dashboard from "./components/Dashboard";
import Graph1 from "./components/Graph1";

function App() {
  return (
    <div className="appContainer">
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/graph" component={Graph1} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
