import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <div className="headerContainer">
      <p className="title">KF Intelligence Cloud Force Graph</p>
    </div>
  );
};

export default Header;
