import React from "react";
import "./Dashboard.css";
// import styled from "styled-components";
import { useHistory } from "react-router-dom";
import sampleData from "./sampleData.json";
import Button from "@mui/material/Button";

// const Button = styled.button`
//   background-color: #4caf50;
//   border: none;
//   color: white;
//   text-align: center;
//   text-decoration: none;
//   display: inline-block;
//   font-size: 16px;
//   margin: 4px 2px;
//   cursor: pointer;
//   border-radius: 5px;
//   width: 50%;
//   height: 40px;
// `;

// const Button = styled.button`
//   justify-content: center;
//   align-items: center;
//   padding: 11px 16px;
//   width: 130px;
//   height: 60px;
//   background: #ffffff;
//   border: 1px solid #00c29b;
//   box-sizing: border-box;
//   border-radius: 8px;
//   &: hover {
//     background-color: #00c29b;
//   }
// `;

const Dashboard = () => {
  const history = useHistory();
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileReader = new FileReader();
    fileReader.readAsText(event.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      history.push("/Graph", { graphData: JSON.parse(e.target.result) });
    };
  };

  return (
    <div className="dashboardContainer">
      <div className="dashRow1">
        <div className="dashCol1">
          <div className="dashCard">
            <div className="dashCardHeader">
              <p>Default Data Graph</p>
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push("/Graph", { graphData: sampleData });
              }}
              style={{
                backgroundColor: "#00C29B",
                width: "187px",
                height: "40px",
                color: "black",
              }}
            >
              Render
            </Button>
          </div>
        </div>
        <div className="dashCol2">
          <div className="dashCard">
            <div className="dashCardHeader">
              <p>Custom Data Graph</p>
            </div>
            <div className="dashCardBody">
              <input
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
                type="file"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleClick}
                style={{
                  backgroundColor: "#00C29B",
                  width: "187px",
                  height: "40px",
                  color: "black",
                }}
              >
                Upload & Render
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
