import React, { useReducer, useEffect } from "react";
import Select from "react-select";
import "./Graph1.css";
import { getCustomGraphData } from "./getGraphData";
import { getGraphFunctions } from "./getGraphFunctions";
import FGraph from "./FGraph";
import MultiSelect from "./MultiSelect";
import { useHistory } from "react-router";
import Button from "@mui/material/Button";

const Graph1 = (props) => {
  const [state, dispatch] = useReducer(graphReducer, initialState);

  const history = useHistory();

  useEffect(() => {
    dispatch({ type: "loadFile", payload: props.location.state.graphData });
  }, [props.location.state.graphData]);

  const {
    probability,
    selected,
    theme,
    themes,
    defaultTheme,
    nodeColor,
    functions,
    funcOptions,
    showGraph,
    graphData,
    customData,
  } = state;

  const handleThemeChange = (e) => {
    dispatch({ type: "theme", payload: e.value });
  };

  const defaultProps = {
    allOption: {
      label: "Select all",
      value: "*",
    },
  };

  const handleMultiSelectChange = (selected) => {
    if (
      selected !== null &&
      selected.length > 0 &&
      selected[selected.length - 1].value === defaultProps.allOption.value
    ) {
      dispatch({ type: "selectedItems", payload: [...funcOptions] });
      return funcOptions;
    }
    dispatch({ type: "selectedItems", payload: [...selected] });
    return selected;
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: "setGraphData" });
  };

  return (
    <div className="graph1Container">
      <div className="g1Row1">
        <p className="graphTitle">3D Rendering</p>
      </div>
      <div className="g1Row1">
        <Button
          onClick={() => history.push("/")}
          variant="contained"
          style={{
            backgroundColor: "#00C29B",
            width: "187px",
            height: "40px",
            color: "black",
            borderRadius: "8px",
          }}
        >
          Back to Dashboard
        </Button>
      </div>
      <div className="g1Row2">
        <div className="g1Row2Col1">
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            Theme:{" "}
          </p>
        </div>
        <div className="g1Row2Col2">
          <Select
            id="theme"
            options={themes}
            defaultValue={defaultTheme}
            onChange={handleThemeChange}
          />
        </div>
      </div>
      <div className="g1Row2">
        <div className="g1Row2Col1">
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            Transition Probability:{" "}
          </p>
        </div>
        <div className="g1Row2Col2">
          <div className="g1Row2Col2Col1">
            <form onSubmit={handleOnSubmit}>
              <input
                type="number"
                name="name"
                min="0"
                step="0.001"
                value={probability}
                className="txtInput"
                onChange={(e) => {
                  if (e.target.value <= 1) {
                    dispatch({
                      type: "field",
                      fieldName: "probability",
                      payload: e.target.value,
                    });
                  } else {
                    dispatch({
                      type: "field",
                      fieldName: "probability",
                      payload: "0",
                    });
                  }
                }}
              />{" "}
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: "#00C29B",
                  width: "100px",
                  height: "32px",
                  color: "black",
                  borderRadius: "8px",
                }}
              >
                {" "}
                Update{" "}
              </Button>
            </form>
          </div>
        </div>
      </div>
      <div className="g1Row2">
        <div className="g1Row2Col1">
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            Functions:{" "}
          </p>
        </div>
        <div className="g1Row2Col2">
          <div className="multiDiv">
            <MultiSelect
              handleMultiSelectChange={handleMultiSelectChange}
              optionSelected={selected}
              colourOptions={funcOptions}
              defaultProps={defaultProps}
              style={{
                width: "300px",
              }}
            />
          </div>
        </div>
      </div>
      <div className="g1Row3">
        <div className="g1Row3Col1">
          {showGraph && customData && (
            <FGraph
              graphData={graphData}
              color={theme}
              nodeColor={nodeColor}
              functions={functions}
            />
          )}
          {!showGraph && (
            <div className="noGraph">
              <p>No functions selected</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const graphReducer = (state, action) => {
  switch (action.type) {
    case "field": {
      return {
        ...state,
        [action.fieldName]: action.payload,
      };
    }
    case "theme": {
      return {
        ...state,
        theme: action.payload,
      };
    }
    case "nodeColor": {
      return {
        ...state,
        nodeColor: action.payload,
      };
    }
    case "showGraph": {
      return {
        ...state,
        showGraph: true,
      };
    }
    case "hideGraph": {
      return {
        ...state,
        showGraph: false,
      };
    }
    case "selectedItems": {
      const selectedItems = [...action.payload];
      let isVisible = true;
      const funcArr = [];
      if (selectedItems && selectedItems.length > 0) {
        selectedItems.forEach((item) => {
          funcArr.push(item.value);
        });
      } else {
        isVisible = false;
      }
      return {
        ...state,
        selected: [...action.payload],
        graphData: getCustomGraphData(
          [...funcArr],
          state.customData,
          state.probability
        ),
        showGraph: isVisible,
        functions: [...funcArr],
      };
    }
    case "setGraphData": {
      const funcArr = [];
      if (state.selected && state.selected.length > 0) {
        state.selected.forEach((item) => {
          funcArr.push(item.value);
        });
      }
      return {
        ...state,
        showGraph: true,
        graphData: getCustomGraphData(
          [...funcArr],
          state.customData,
          state.probability
        ),
      };
    }
    case "loadFile": {
      const funcArr = getGraphFunctions(action.payload).map((name) => {
        return { value: name, label: name };
      });
      return {
        ...state,
        selected: funcArr,
        funcOptions: funcArr,
        functions: getGraphFunctions(action.payload),
        customData: action.payload,
        graphData: getCustomGraphData(
          [...getGraphFunctions(action.payload)],
          action.payload,
          state.probability
        ),
      };
    }
    default:
      return state;
  }
};

const initialState = {
  probability: 0.01,
  functions: [],
  selected: [],
  theme: "black",
  nodeColor: "subFunction",
  nodeColors: [
    { value: "subFunction", label: "Sub Function" },
    { value: "level", label: "Level" },
    { value: "subLevel", label: "Sub Level" },
    { value: "grade", label: "Grade" },
  ],
  defaultNodeColor: { value: "subLevel", label: "Sub Level" },
  themes: [
    { value: "black", label: "Dark" },
    { value: "#fff", label: "Light" },
  ],
  defaultTheme: { value: "black", label: "Dark" },
  funcOptions: [],
  showGraph: true,
  graphData: null,
  customData: null,
};

export default Graph1;
