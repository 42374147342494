// import sampleData from "./sampleData.json";

// function getGraphData(functions, probe = 0) {
//   let nodesList = [];
//   let linksList = [];
//   if (functions.length > 0) {
//     functions.forEach((func) => {
//       const nodes = sampleData.graph.nodes.filter(
//         (nodeItm) => nodeItm.subFunction === func
//       );
//       nodes.forEach((element) => {
//         nodesList.push(element);
//       });
//     });

//     nodesList.forEach((nodeItm) => {
//       let itemsArr = sampleData.graph.edges.filter((edge) => {
//         return edge.source.id === nodeItm.id || edge.target.id === nodeItm.id;
//       });
//       itemsArr.forEach((itm) => {
//         const hasLinkAlready = linksList.filter((item) => {
//           return (
//             itm.source.id === item.source.id && itm.target.id === item.target.id
//           );
//         });
//         if (hasLinkAlready && hasLinkAlready.length > 0) {
//         } else {
//           if (
//             functions.includes(itm.source.subFunction) &&
//             functions.includes(itm.target.subFunction)
//           ) {
//             linksList.push(itm);
//           }
//         }
//       });
//     });
//   } else {
//     nodesList = sampleData.graph.nodes;
//     linksList = sampleData.graph.edges;
//   }
//   if (probe > 0) {
//     return {
//       nodes: nodesList,
//       links: linksList.filter((item) => {
//         return item.transition_prob > probe;
//       }),
//     };
//   }
//   return { nodes: nodesList, links: linksList };
// }

const getCustomGraphData = (functions, customData, probe = 0) => {
  let nodesList = [];
  let linksList = [];
  if (functions.length > 0) {
    functions.forEach((func) => {
      const nodes = customData.graph.nodes.filter(
        (nodeItm) => nodeItm.subFunction === func
      );
      nodes.forEach((element) => {
        nodesList.push(element);
      });
    });

    nodesList.forEach((nodeItm) => {
      let itemsArr = customData.graph.edges.filter((edge) => {
        if (edge.source.id && edge.target.id) {
          return edge.source.id === nodeItm.id || edge.target.id === nodeItm.id;
        } else {
          return edge.source === nodeItm.id || edge.target === nodeItm.id;
        }
      });
      if (probe > 0.01) {
        if (itemsArr && itemsArr.Length > 0) {
          itemsArr = itemsArr.filter((item) => {
            return item.transition_prob > probe;
          });
        }
      }
      itemsArr.forEach((itm) => {
        const hasLinkAlready = linksList.filter((item) => {
          if (item.source.id && item.target.id) {
            return (
              itm.source.id === item.source.id &&
              itm.target.id === item.target.id
            );
          } else {
            return itm.source === item.source && itm.target === item.target;
          }
        });

        if (hasLinkAlready && hasLinkAlready.length > 0) {
        } else {
          if (itm.source.subFunction && itm.target.subFunction) {
            if (
              functions.includes(itm.source.subFunction) &&
              functions.includes(itm.target.subFunction)
            ) {
              if (probe > 0.01 ? itm.transition_prob > probe : true)
                linksList.push(itm);
            }
          } else {
            if (probe > 0.01 ? itm.transition_prob > probe : true)
              linksList.push(itm);
          }
        }
      });
    });
  } else {
    nodesList = customData.graph.nodes;
    linksList = customData.graph.edges;
  }

  let linkNodes = [];
  linksList.forEach((linkItm) => {
    if (linkItm.source.id && linkItm.target.id) {
      linkNodes.push(linkItm.source.id);
      linkNodes.push(linkItm.target.id);
    } else {
      linkNodes.push(linkItm.source);
      linkNodes.push(linkItm.target);
    }
  });
  linkNodes = [...new Set(linkNodes)];
  let nodesTrimmed = [];
  nodesList.forEach((nodeItm) => {
    if (linkNodes.includes(nodeItm.id)) {
      nodesTrimmed.push(nodeItm);
    }
  });
  // if (probe > 0.01) {
  //   const probeList = linksList.filter((item) => {
  //     return item.transition_prob > probe;
  //   });
  //   return {
  //     nodes: nodesTrimmed,
  //     links: probeList,
  //   };
  // }
  return { nodes: nodesTrimmed, links: linksList };
};

export { getCustomGraphData };
